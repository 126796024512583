import { BodyText } from "../../atoms/Text";
import { styled } from "@mui/system";
import { Button, SearchDetailButton } from "../../atoms/Button";
import { Box, InputAdornment } from "@mui/material";
import { SearchIcon } from "../../atoms/icons";
import {
  ForwardTextField,
  AnaluzationForwardTextField,
} from "../../atoms/TextField";
import useLocalStorage from "../../../hooks/localstorage";
import { useQuery } from "react-query";
import { SleepwellApi } from "@sleepwell_new_platform/common-functions/lib/api";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Analyzation, Patients } from "@sleepwell_new_platform/common-types";
import { DataGrid as MuiDataGrid } from "./DataGrid";
import { SimpleBackdrop } from "../../molecules/Backdrop";
import { getPatients } from "../../../aws/api/patients";
import {
  EditModal,
  Modal,
  EditWideModal,
  PdfDownloadModal,
} from "../../molecules/Modal";
import { Progress } from "../../atoms/Progress";
import { HeadlessTable } from "../../molecules/Table";
import {
  analyzationDataColums,
  attributeColumns,
  analyzationResultColumns,
} from "../../../static/analyticsRequest";
import MuiLink from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { ArrowIcon } from "../../../icons/analyzation";
import { DownloadIcon } from "../../atoms/icons/DownloadIcon";
import { Accordion } from "../../molecules/Accordion";
import { SearchArea } from "./SearchArea";
import Collapse from "@mui/material/Collapse";
import { DateRange } from "../../molecules/DateRangePicker";
import { nl2br } from "../../../utils/nl2br";
import dayjs from "../../../lib/dayjs";
import CustomizedSnackbar from "../../atoms/CustomizedSnackbar";
import { SleepAnalyticsDetail } from "../../templates/SleepAnalyticsDetail";
import { AnalyzationResult } from "@sleepwell_new_platform/common-types/src/analyzationResults";
import { exportCSV } from "../../../utils/exportCSV";
import { convertToCSVFormat } from "../../../utils/analyzations/convertToCSVFormat";
import { SleepAnalyticsDocAReport } from "../../templates/SleepAnalyticsDocAReport";
import { SleepAnalyticsDocA3Report } from "../../templates/SleepAnalyticsDocA3Report";
import { SelectInput } from "../../molecules/Select";
import MuiButton from "@mui/material/Button";
import { AnalyzationStatus } from "../../../utils/analyzations/analyzations";
import {
  getAnalyzationStatusLabel,
  isAnalyzationProcessDone,
} from "../../../utils/analyzations";
import JSZip from "jszip";
import { formatDateToYYYYMMDDHHMMSS } from "../../../utils/Moment";
import { roundToSecondDecimalPlace } from "../../../utils/analyzations/roundFunctions";
import { GridSortModel } from "@mui/x-data-grid";
import OpenSearchClient from "@sleepwell_new_platform/common-functions/lib/opensearch";

export const Analyzations = () => {
  const Api = new SleepwellApi(axios);
  const group = localStorage.getItem('group');
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [clinicId] = useLocalStorage("clinicId", "");
  const [analyzationState, setAnalyzationState] = useState<Analyzation>();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [selectList, setSelectList] = useState<string[]>([]);
  const [isOpenSearchBox, setIsOpenSearchBox] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [openReportModal, setOpenReportModal] = useState<boolean>(false);
  const [openDocA4ReportModal, setOpenDocA4ReportModal] =
    useState<boolean>(false);
  const [openDocA3ReportModal, setOpenDocA3ReportModal] =
    useState<boolean>(false);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState<boolean>(false);
  const [openPdfDownloadModal, setOpenPdfDownloadModal] =
    useState<boolean>(false);
  const [fftDownload, setFftDownload] = useState<boolean>(false);
  const [selectAnalyzationList, setSelectAnalyzationList] = useState<any[]>([]);
  //const [clinicName, setClinicName] = useState<string>('');
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });
  const [firstAnalyzationDateRange, setFirstAnalyzationDateRange] =
    useState<DateRange>({ startDate: null, endDate: null });
  const [affiliationName, setAffiliationName] = useState<string>("");
  const [edfFileName, setEdfFileName] = useState<string>("");
  const [lowerAge, setLowerAge] = useState<string | number | null>("");
  const [upperAge, setUpperAge] = useState<string | number | null>("");
  const [equipmentId, setEquipmentId] = useState<string>("");
  const [prefecture, setPrefecture] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [medicineInfo, setMedicineInfo] = useState<string>("");
  const [sickHistory, setSickHistory] = useState<string>("");
  const [patientNumber, setPatientNumber] = useState<string | number | null>(
    ""
  );
  const [waitingForAnalyze, setWaitingForAnalyze] = useState<boolean>(false);
  const [waitingForReCalculating, setWaitingForReCalculating] =
    useState<boolean>(false);
  const [starting, setStarting] = useState<boolean>(false);
  const [analyzing, setAnalyzing] = useState<boolean>(false);
  const [calculating, setCalculating] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [waitingForConfirm, setWaitingForConfirm] = useState<boolean>(false);
  const [waitingForConfirmReport, setWaitingForConfirmReport] =
    useState<boolean>(false);
  const [requestForReMeasure, setRequestForReMeasure] =
    useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [diseaseId, setdiseaseId] = useState<string>("");
  const [filterAnalyzations, setFilterAnalyzations] = useState<any>(null);
  const [filterFlag, setFilterFlag] = useState<boolean>(false);
  const [allAnalyzing, setAllAnalyzing] = useState<boolean>(false);
  const [allFinished, setAllFinished] = useState<boolean>(false);
  const [filterLoading, setFilterLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [analyzationsData, setAnalyzationsData] = useState<Analyzation[]>([]);
  const [total, setTotal] = useState<number>(0);
  const OpenSearch = useMemo(() => new OpenSearchClient(axios), []);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

 
  const fetchMoreData = useCallback(async (data? : {[key: string]: string | number | null | undefined}) => {
    if(loading) return;
    if(!clinicId) return;
    setLoading(true);
    try {
      let query;
      if(!data){
        query = {
          query: {
            bool: {
              must: [
                { match: { isDeleted: 0 } },
                { match: { clinicId: clinicId } },
              ]
            },
          },
          sort:  [{ firstAnalyzeRequestDate: { order: 'desc' } }],
          size: paginationModel.pageSize,
          from: paginationModel.page * paginationModel.pageSize,
        };
      const response = await OpenSearch.search<Analyzation>('new_analyzations', query);
      const newRecord = response.hits.hits.filter(hit => hit._source !== undefined).map((hit) => hit._source as Analyzation);
      setAnalyzationsData(newRecord);
      setTotal(typeof response.hits.total === 'number' ? response.hits.total : response.hits.total?.value ?? 0);
    } else {
      const filterParam = Object(data);
      for (let key in filterParam) {
        // もしプロパティの値がnullなら、そのプロパティを削除
        if (filterParam[key] === "" || filterParam[key] === null || filterParam[key] === undefined) {
          delete filterParam[key];
        }
      }  
      const pageSize = 125;
      const hasStatusFilters = filterParam.waitingForAnalyze || filterParam.waitingForReCalculating || filterParam.starting || filterParam.analyzing || filterParam.calculating || filterParam.confirmed || filterParam.waitingForConfirmReport || filterParam.waitingForConfirm || filterParam.requestForReMeasure || filterParam.failed;
      query = {
          bool: {
            must: [
              { match: { isDeleted: 0 } },
              { match: { clinicId: clinicId } },
              ...(filterParam.affiliationName ? [{ wildcard: { "affiliation.name": `*${filterParam.affiliationName}*` } }] : []),
              ...(filterParam.patientNumber ? [{ wildcard: { "patient.patientNumber": `*${filterParam.patientNumber}*` } }] : []),
              ...(filterParam.edfFileName ? [{ wildcard: { edfFileName: `*${filterParam.edfFileName}*` } }] : []),
              ...(filterParam.equipmentId ? [{ wildcard: { equipmentId: `*${filterParam.equipmentId}*` } }] : []),
              ...(filterParam.medicineInfo ? [{ wildcard: { medicineInfo: `*${filterParam.medicineInfo}*` } }] : []),
              ...(filterParam.sickHistory ? [{ wildcard: { "patient.sickHistory": `*${filterParam.sickHistory}*` } }] : []),
              ...(filterParam.prefecture ? [{ match_phrase: { prefecture: filterParam.prefecture } }] : []),
              ...(filterParam.diseaseId ? [{ match_phrase: { "patient.diseases.diseasesId": filterParam.diseaseId} }] : []),
              ...(filterParam.gender ? [{ match_phrase: { gender: filterParam.gender} }] : []),
              ...(filterParam.lowerAge ? [{ range: { age: { gte: filterParam.lowerAge } } }] : []),
              ...(filterParam.upperAge ? [{ range: { age: { lte: filterParam.upperAge } } }] : []),
              ...(filterParam.dateRangeStartDate && filterParam.dateRangeEndDate  ? [{ range: { measureStartDate: { gte: Number(filterParam.dateRangeStartDate), lte: Number(filterParam.dateRangeEndDate) } } }] : []),
              ...(filterParam.dateRangeStartDate && !filterParam?.dateRangeEndDate  ? [{ range: { measureStartDate: { gte: Number(filterParam.dateRangeStartDate) } } }] : []),
              ...(!filterParam.dateRangeStartDate && filterParam.dateRangeEndDate ? [{ range: { measureStartDate: { lte: Number(filterParam.dateRangeEndDate) } } }] : []),
              ...(filterParam.firstAnalyzationStartDate && filterParam.firstAnalyzationEndDate ? [{ range: { firstAnalyzeRequestDate: { gte: Number(filterParam.firstAnalyzationStartDate), lte: Number(filterParam.firstAnalyzationEndDate) } } }] : []),
              ...(filterParam.firstAnalyzationStartDate && !filterParam.firstAnalyzationEndDate ? [{ range: { firstAnalyzeRequestDate: { gte: Number(filterParam.firstAnalyzationStartDate) } } }] : []),
              ...(!filterParam.firstAnalyzationStartDate && filterParam.firstAnalyzationEndDate ? [{ range: { firstAnalyzeRequestDate: { lte: Number(filterParam.firstAnalyzationEndDate) } } }] : []),
            ],
            ...(hasStatusFilters ? {
              should: [
                ...(filterParam.waitingForAnalyze ? [{ match: { status: "waitingForAnalyze" }}] : []),
                ...(filterParam.waitingForReCalculating ? [{ match: { status: "waitingForReCalculating" }}] : []),
                ...(filterParam.starting ? [{ match: { status: "starting" }}] : []),
                ...(filterParam.analyzing ? [{ match: { status: "analyzing" }}] : []),
                ...(filterParam.calculating ? [{ match: { status: "calculating" }}] : []),
                ...(filterParam.confirmed ? [{ match: { status: "confirmed" }}] : []),
                ...(filterParam.waitingForConfirmReport ? [{ match: { status: "waitingForConfirmReport" }}] : []),
                ...(filterParam.waitingForConfirm ? [{ match: { status: "waitingForConfirm" }}] : []),
                ...(filterParam.requestForReMeasure ? [{ match: { status: "requestForReMeasure" }}] : []),
                ...(filterParam.failed ? [{ match: { status: "failed" }}] : []),
              ],
              minimum_should_match: 1
            } : {})
          },
      };
      let allRecords = [];
      let count = 0;
      let response = await OpenSearch.search<Analyzation>('new_analyzations', {
        query: query,
        sort: [{ firstAnalyzeRequestDate: { order: 'desc' } }],
        size: pageSize,
        from: 0
      });
      allRecords = response.hits.hits.map(hit => hit._source);
      const total = response.hits.total　as { value: number } 
      while (total.value > allRecords.length) {
        response = await OpenSearch.search<Analyzation>('new_analyzations', {
          query: query,
          sort: [{ firstAnalyzeRequestDate: { order: 'desc' } }],
          size: pageSize,
          from: (count + 1) * pageSize  // 次のページへ
        });
        allRecords = allRecords.concat(response.hits.hits.map(hit => hit._source));
        count += 1;  // ページカウントをインクリメント
      }

        setFilterAnalyzations(allRecords);
        setFilterFlag(true);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [OpenSearch, loading, paginationModel.page, paginationModel.pageSize, clinicId]);

  useEffect(() => {
      fetchMoreData();
  }, [paginationModel]);
  useEffect(() => {
    const interval = setInterval(() => {
      fetchMoreData();
    }, 20000);
    return () => clearInterval(interval);
  }, [fetchMoreData]);

  const [snackState, setSnackState] = useState<{
    open: boolean;
    message: string;
    success: boolean;
  }>({ open: false, message: "", success: false });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'firstAnalyzeRequestDate', sort: 'desc' },
  ]);
  const { data: patientsResult } = useQuery("patients", () =>
    getPatients(clinicId).then((res) => res.data)
  );
  const { data: affiliation } = useQuery("affiliations", () =>
    Api.getAffiliations(clinicId).then((res) => res.data)
  );
  const id = useMemo(() => {
    return localStorage?.id ?? "";
  }, []);

  const { data: diseases } = useQuery("diseases", () =>
    Api.getDiseases().then((res) => res.data.result.Items)
  );
  const { data: analyzationResult, refetch: refetchAnalyzationResult } =
    useQuery(
      "analyzationResults",
      async () => {
        if (!analyzationState) return;
        const res = await Api.getAnalyzationResult(analyzationState.id);
        return res.data;
      },
      {
        refetchInterval: 30000, // 30 seconds in milliseconds
      }
    );
  const { data: clinicData } = useQuery(
    "clinic",
    async () => await Api.getClinics(clinicId)
  );
  const analyzationResultData = useMemo(() => {
    return (
      analyzationResult?.result.Items?.filter(
        (item: AnalyzationResult) => item.isDeleted === 0
      ).sort((a: AnalyzationResult, b: AnalyzationResult) =>
        a.createdAt > b.createdAt ? -1 : 1
      )[0] ?? null
    );
  }, [analyzationResult]);

  const { data: averageItems } = useQuery(
    "averageItems",
    async () => await Api.getAverageItems()
  );
  
  useEffect(() => {
    if (analyzationState?.id) refetchAnalyzationResult();
  }, [analyzationState]);

  useEffect(() => {
    const list: any = [];
    selectList.map(async (analyzationId) => {
      const tmpAnalyzations: Analyzation [] = filterFlag ? filterAnalyzations as Analyzation[] : analyzationsData;
      const data = tmpAnalyzations.find(
        (analyzation) => analyzation.id === analyzationId
      );
      if (data) {
        list.push(data);
      }
    });
    setSelectAnalyzationList(list);
    // eslint-disable-next-line
  }, [selectList, filterFlag, filterAnalyzations, analyzationsData]);

  const handleDownloadEDF = async (analyzationData: Analyzation) => {
    setIsDisabled(true);
    const response = await Api.getSignedUrl(
      analyzationData.edfFileName,
      analyzationData.patientId,
      analyzationData.clinicId,
      "get"
    );
    const url = response.data.result;
    setIsDisabled(false);
    if (!url) return;
    window.open(url);
  };

  const handleDownloadSingleFFT = async (analyzationData: Analyzation) => {
    setIsDisabled(true);
    const response = await Api.getSignedUrl(
      analyzationData.edfFileName.replace(".EDF", ".txt"),
      analyzationData.patientId,
      analyzationData.clinicId,
      "get"
    );
    const url = response.data.result;
    setIsDisabled(false);

    // FFTダウンロードフラグを立てる
    await Api.putAnalyzation({
      ...analyzationData,
      isFFTDownload: 1,
      updatedAt: dayjs().valueOf(),
      updatedBy: id,
    });
    if (!url) return;
    window.open(url);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const clinic = clinicData ? clinicData[0] : null;

  const [lang, setLang] = useState<"ja" | "en">(clinic?.reportLang ?? "ja");
  const updateAnalyzation = useCallback(
    async (selectedAnalyzationState: Analyzation) => {
      if (!selectedAnalyzationState) return;
      await Api.putAnalyzation({
        ...selectedAnalyzationState,
        updatedAt: dayjs().valueOf(),
        updatedBy: id,
      });
      //初回入ってきてない
      setTimeout(() => {
        refetchAnalyzations();
      }, 5000)
    }, [filterAnalyzations, id, filterFlag, paginationModel.page, paginationModel.pageSize]
  );

  useEffect(() => {
    setLang(clinic?.reportLang ?? "ja");
  }, [clinic]);

  const rows = useMemo(() => {
    if (!analyzationState) return [];
    const isAllowedBrowseAllReport = clinic?.isAllowedBrowseAllReport;
    const reportType = clinic?.reportType;
    const isAllowedFFTDownload = clinic?.isAllowedFFTDownload;
    let filteredAnalyzationDataColums = analyzationDataColums;
    if (["failed", "requestForReMeasure"].includes(analyzationState.status)) {
      filteredAnalyzationDataColums = analyzationDataColums.filter(
        (doc) =>
          !["doctorA3", "doctorA4", "research", "fftResult"].includes(doc.value)
      );
    }
    if (!isAllowedBrowseAllReport) {
      const excludeColums = ["doctorA4", "doctorA3", "research"].filter(
        (column) => column !== reportType
      );
      filteredAnalyzationDataColums = filteredAnalyzationDataColums.filter(
        (column) => !excludeColums.includes(column.value)
      );
    }
    if (!isAllowedFFTDownload) {
      filteredAnalyzationDataColums = filteredAnalyzationDataColums.filter(
        (column) => column.value !== "fftResult"
      );
    }
    if (!clinic?.isEnableAthens) {
      filteredAnalyzationDataColums = filteredAnalyzationDataColums.filter(
        (column) => column.value !== "athens"
      );
    }
    if (!clinic?.isEnableSnoreQuestion) {
      filteredAnalyzationDataColums = filteredAnalyzationDataColums.filter(
        (column) => column.value !== "snoreQuestion"
      );
    }
    if (analyzationState?.status === "failed") {
      filteredAnalyzationDataColums = filteredAnalyzationDataColums.filter(
        (column) => !["doctorA3", "doctorA4", "research"].includes(column.value)
      );
    }
    if (!analyzationState) return [];
    return filteredAnalyzationDataColums.map((column) => {
      const value = analyzationState[column.value as keyof Analyzation];
      if (column.value === "status") {
        return {
          title: column.label,
          value: value
            ? getAnalyzationStatusLabel(String(value) as AnalyzationStatus)
            : "",
        };
      }
      if (column.value === "measureStartDate") {
        return {
          title: column.label,
          value: value
            ? dayjs(value as number).format("YYYY年MM月DD日 HH:mm:ss")
            : "",
        };
      }
      if (column.value === "doctorA3") {
        return {
          title: column.label,
          value: (
            <FlexLinkWrap>
              <MuiLink
                underline="none"
                onClick={() => {
                  setOpenDocA3ReportModal(true);
                }}
                style={{ marginRight: 8 }}
              >
                レポート表示
              </MuiLink>
              <ArrowIcon />
            </FlexLinkWrap>
          ),
        };
      }
      if (column.value === "doctorA4") {
        return {
          title: column.label,
          value: (
            <FlexLinkWrap>
              <MuiLink
                underline="none"
                onClick={() => setOpenDocA4ReportModal(true)}
                style={{ marginRight: 8 }}
              >
                レポート表示
              </MuiLink>
              <ArrowIcon />
            </FlexLinkWrap>
          ),
        };
      }
      if (column.value === "research") {
        return {
          title: column.label,
          value: (
            <FlexLinkWrap>
              <MuiLink
                underline="none"
                onClick={() => {
                  setOpenReportModal(true);
                }}
                style={{ marginRight: 8 }}
              >
                レポート表示
              </MuiLink>
              <ArrowIcon />
            </FlexLinkWrap>
          ),
        };
      }
      if (column.value === "fftResult") {
        return {
          title: column.label,
          value: (
            <FlexLinkWrap>
              <DownloadIcon />
              <MuiLink
                underline="none"
                onClick={() => {
                  handleDownloadSingleFFT(analyzationState);
                  if (
                    !analyzationState.isFFTDownload ||
                    analyzationState.isFFTDownload === 0
                  ) {
                    updateAnalyzation({
                      ...analyzationState,
                      isFFTDownload: 1,
                    });
                  }
                }}
                style={{ marginLeft: 8 }}
              >
                FFTファイルのダウンロード
              </MuiLink>
            </FlexLinkWrap>
          ),
        };
      }
      if (column.value === "patientNumber") {
        const patients = patientsResult?.result.Items;
        const patientNumber = patients
          ? patients.find((p) => p.id === analyzationState.patientId)
              ?.patientNumber
          : "";
        return {
          title: column.label,
          value: patientNumber,
        };
      }
      if (column.value === "startmeasure") {
        return {
          title: column.label,
          value: value ? dayjs(value as number).format("YYYY-MM-DD HH:mm") : "",
        };
      }
      return {
        title: column.label,
        value: nl2br(String(value)),
      };
    });
  }, [analyzationState, clinic, patientsResult]);

  const attributeRows = useMemo(() => {
    if (!analyzationState) return [];
    return attributeColumns.filter((doc) => !(doc.value === "diseaseList" && analyzationState?.useOfDiseaseMedicalTreatment !== 1)).map((column) => {
      const key = Object.keys(analyzationState).find(
        (v) => v === column.value
      ) as keyof Analyzation;
      if (column.value === "diseaseList") {
        const diseaseList = analyzationState?.diseaseList
          ? typeof analyzationState?.diseaseList === "string"
            ? JSON.parse(analyzationState.diseaseList as unknown as string)
            : analyzationState.diseaseList
          : [];
        const diseases = diseaseList?.map((disease: any, index: number) => {
          return (
            <tr>
              <td>{disease?.other}</td>
              <td>{disease?.firstVisitDate}</td>
            </tr>
          );
        });
        return {
          title: column.label,
          value: (
            <>
            <table>
               <tr>
                <th style={{ width: "10%" }}>主疾患</th>
              </tr>
              <tr>
                <th style={{fontWeight: 500, width: '30' }}>{diseaseList[0]?.diseaseName ?? ""}</th>
              </tr>
              </table>
              <table>
              <tr>
                <th style={{ width: "10%" }}>疾病</th>
                <th style={{ width: "20%" }}>初診日</th>
              </tr>
              {diseases}
            </table>
            </>
          ),
        };
      } else {
        return {
          title: column.label,
          value: key ? String(analyzationState[key]) : "",
        };
      }
    });
  }, [analyzationState, clinic]);

  const analyzationResultRow = useMemo(() => {
    const isAllWake = analyzationResultData?.sl === 0 ? true : false;
    return analyzationResultColumns.map((column) => {
      let value = analyzationResultData
        ? String(
            getSleepCycle(
              column.value as keyof AnalyzationResult,
              analyzationResultData
            )
          )
        : "";
      if (column.value === "firstSleepCycleDeltaPow") {
        // "第1睡眠周期の総δパワー値"
        value = String(
          roundToSecondDecimalPlace(
            analyzationResultData?.firstNonremDeltaPowerInfo.totalDeltaPower
          ) ?? "-"
        );
      } else if (column.value === "firstSleepCycleTime") {
        // "第1睡眠周期時間(REMは除く)"
        value = String(
          roundToSecondDecimalPlace(
            analyzationResultData?.firstNonremDeltaPowerInfo.totalSleepCycleTime
          ) ?? "-"
        );
      } else if (column.value === "firstSleepCycleDeltaPowParMin") {
        // "第1睡眠周期のδパワー値/ 1分あたり"
        value = String(
          roundToSecondDecimalPlace(
            analyzationResultData?.firstNonremDeltaPowerInfo.totalDeltaPowParMin
          ) ?? "-"
        );
      } else if (column.value === "totalNonremDeltaPowParMin") {
        //  "第1睡眠周期/睡眠時間"
        value = String(
          roundToSecondDecimalPlace(
            analyzationResultData?.firstNonremDeltaPowerInfo
              .deltaPowParTotalDeltaPow
          ) ?? "-"
        );
      } else if (column.value === "firstSleepCycleDeltaPowParToalDeltaPow") {
        // SPTのδパワー値/1分あたり
        value = String(
          roundToSecondDecimalPlace(
            analyzationResultData?.totalNonremDeltaPowParMin
          ) ?? "-"
        );
      } else if (column.value === "beforeW2hWIndex") {
        value =
          String(
            roundToSecondDecimalPlace(analyzationResultData?.beforeW2hWIndex)
          ) ?? "";
      } else if (column.value === "beforeW2hWaso") {
        value =
          String(
            roundToSecondDecimalPlace(
              ((analyzationResultData?.beforeW2hWaso ?? 0) / 120) * 100 > 100
                ? 100
                : ((analyzationResultData?.beforeW2hWaso ?? 0) / 120) * 100
            )
          ) ?? "";
      } else if (["sl", "spt", "tst"].includes(column.value as string)) {
        value = isAllWake
          ? "-"
          : String(
              analyzationResultData
                ? analyzationResultData[column.value as keyof AnalyzationResult]
                : "-"
            );
      }
      return {
        title: column.label,
        value: value,
      };
    });
  }, [analyzationResultData]);

  const rejectMessageList = useMemo(() => {
    const rejectMessageList = analyzationState?.rejectMessageList;
    if (!rejectMessageList) return [];
    const isProcessDone = isAnalyzationProcessDone(
      analyzationState?.status as AnalyzationStatus
    );
    let message = Array.from(
      new Set(
        rejectMessageList
          .filter(
            (doc) =>
              isProcessDone &&
              doc.userMessage !==
                "スリープウェルにて解析結果を検証中です。しばらくお待ちください。"
          )
          .map((doc) => doc.userMessage)
      )
    ).join("\n");
    if (
      analyzationState?.status === AnalyzationStatus.WAITING_FOR_CONFIRM ||
      analyzationState?.status === AnalyzationStatus.WAITING_FOR_CONFIRM_REPORT
    ) {
      message =
        "スリープウェルにて解析結果を検証中です。しばらくお待ちください。";
    }
    if (analyzationState?.status === "failed") {
      message =
        "このデータは処理できない可能性があります。スリープウェルでデータを確認しますので、しばらくお待ちください。";
    }
    return [
      {
        title: "状態",
        value: getAnalyzationStatusLabel(
          analyzationState?.status as AnalyzationStatus
        ),
      },
      { title: "状態詳細", value: message },
    ];
  }, [analyzationState]);

  const updateCommentForClinic = async () => {
    if (!analyzationState) return;
    setDisabled(true);
    await Api.putAnalyzation({
      ...analyzationState,
      isDeleted: 0,
      updatedAt: dayjs().valueOf(),
      updatedBy: id,
    })
      .then((res) => {
        setOpen(false);
        setDisabled(false);
        setSnackState({
          open: true,
          message: "コメントを更新しました",
          success: true,
        });
      })
      .catch((err) => {
        // setIsDisabledDelete(false);
        setDisabled(false);
        setSnackState({
          open: true,
          message: "コメントの更新に失敗しました",
          success: false,
        });
        return;
      });
  };
  const handleDownLoadCSV = async () => {
    // selectedListの格納されているanalyzationデータをselctedAnalyzationに格納
    const sortColumn = sortModel[0].field as keyof Analyzation;
    const tmpAnalyzations = filterFlag ? filterAnalyzations as Analyzation[] : analyzationsData;
    const selectedAnalyzation = tmpAnalyzations?.filter(
      (analyzation) =>
        selectList.includes(analyzation.id) &&
        isAnalyzationProcessDone(analyzation.status as AnalyzationStatus)
    );
    if(sortColumn){
      selectedAnalyzation.sort((a: Analyzation, b: Analyzation) => {
        if (sortModel[0].sort === 'asc') {
          return (a[sortColumn] ?? '') > (b[sortColumn] ?? '') ? 1 : -1;
        } else {
          return (a[sortColumn] ?? '') < (b[sortColumn] ?? '') ? 1 : -1;
        }
      });
    }
    const isSEASD = selectedAnalyzation.some(
      (analyzation) => analyzation.moduleName === "SEAS-D"
    )
    const isSEASG = selectedAnalyzation.some(
      (analyzation) => analyzation.moduleName === "SEAS-G"
    );
    if (isSEASD && isSEASG) {
      setSnackState({
        open: true,
        message: "SEAS-DとSEAS-Gの結果は同時にCSV出力できません",
        success: false,
      });
      return;
    }

    const analyzationResult = await Promise.all(
      selectedAnalyzation.map(async (analyzation) => {
        const result = await Api.getAnalyzationResult(analyzation.id);
        const sortedFilteredResult = result.data.result.Items.filter((item: AnalyzationResult) => item.isDeleted === 0).sort((a: AnalyzationResult, b: AnalyzationResult) => 
        a.createdAt > b.createdAt ? -1 : 1);
      return sortedFilteredResult[0] as AnalyzationResult;
      })
    );
    const list = convertToCSVFormat(
      selectedAnalyzation,
      patientsResult?.result.Items ?? [],
      affiliation?.result.affiliations.Items ?? [],
      analyzationResult,
      diseases ?? [],
      clinic,
      affiliation?.result.industries.Items ?? [],
      affiliation?.result.occupations.Items ?? []
    );
    exportCSV(list, `analyzation_${dayjs().format("YYYYMMDD-HHmmss")}`);
  };
  const downloadFTTFiles = async () => {
    const formatDateToyyyymmddhhmmss = formatDateToYYYYMMDDHHMMSS(new Date());
    const fftDownloadedFolderName = "fft_files_" + formatDateToyyyymmddhhmmss;
    //setDisabled(true);
    const selectedAnalyzationList: Array<any> = [];
    selectList.map(async (analyzationId) => {
      const analyzationData = filterFlag ? filterAnalyzations.find(
        (analyzation: Analyzation) => analyzation.id === analyzationId
      ): analyzationsData?.find(
        (analyzation) => analyzation.id === analyzationId
      );
      if (
        analyzationData?.status === AnalyzationStatus.CONFIRMED ||
        analyzationData?.status === AnalyzationStatus.WAITING_FOR_CONFIRM ||
        analyzationData?.status ===
          AnalyzationStatus.WAITING_FOR_CONFIRM_REPORT ||
        analyzationData?.status === AnalyzationStatus.REQUEST_FOR_RE_MEASURE
      ) {
        selectedAnalyzationList.push(analyzationData);
      }
    });

    // ファイルのダウンロードを並列に行うためのPromise配列を作成
    const downloadPromises = selectedAnalyzationList.map(
      async (selectedAnalyzation: any) => {
        //const response = await fetch(url);
        const fftUrl = selectedAnalyzation?.edfUrl.replace(".EDF", ".txt");
        const fftFileName = fftUrl.split("/").pop();
        const getSignedUrlResponse = await Api.getSignedUrl(
          fftFileName,
          selectedAnalyzation.patientId,
          selectedAnalyzation.clinicId,
          "get"
        );
        const url = getSignedUrlResponse.data.result;
        const response = await fetch(url);

        // FFTダウンロードフラグを立てる
        await Api.putAnalyzation({
          ...selectedAnalyzation,
          isFFTDownload: 1,
          updatedAt: dayjs().valueOf(),
          updatedBy: id,
        });

        return {
          fileName: `${fftDownloadedFolderName}/` + fftFileName,
          data: await response.blob(),
        };
      }
    );

    // 全てのファイルのダウンロードが完了したら処理を実行
    const filesData = await Promise.all(downloadPromises);
    if (selectedAnalyzationList.length > 1) {
      // JSZipインスタンスを作成
      const zip = new JSZip();

      // zip化するファイルを追加
      filesData.forEach((fileData: any) => {
        zip.file(fileData.fileName, fileData.data);
      });
      // zipファイルを生成
      const zipBlob = await zip.generateAsync({ type: "blob" });
      // zipファイルをダウンロード
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(zipBlob);
      downloadLink.download = `${fftDownloadedFolderName}.zip`;
      downloadLink.click();
    } else if (selectedAnalyzationList.length === 1) {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(filesData[0].data);
      downloadLink.download =
        filesData[0].fileName.split("/").pop() || filesData[0].fileName;
      downloadLink.click();
    }
  };
  // 想定当時の年齢を計算する
  const analyzationAge = useMemo(() => {
    if (!analyzationState?.measureStartDate) return "";
    const birthDate = analyzationState?.patient?.birthday;
    const measureStartDate = analyzationState?.measureStartDate;
    if (!birthDate || !measureStartDate) return "";
  
    const birth = dayjs(birthDate);
    const measureStart = dayjs(measureStartDate);
  
    // 年の差を計算
    let age = measureStart.year() - birth.year();
  
    // 測定日が誕生日より前の場合、年齢を1引く
    if (measureStart.month() < birth.month() || 
        (measureStart.month() === birth.month() && measureStart.date() < birth.date())) {
      age--;
    }
    return age;
  }, [analyzationState?.measureStartDate, analyzationState?.patient?.birthday]);

  const refetchAnalyzations = useCallback(async () => {
    const filterParams = {
      affiliationName: affiliationName,
      dateRangeStartDate: convertStartOfDay(dateRange.startDate)?.getTime().toString(),
      dateRangeEndDate: convertEndOfDay(dateRange.endDate)?.getTime().toString(),
      firstAnalyzationStartDate:
      convertStartOfDay(firstAnalyzationDateRange.startDate)?.getTime(),
      firstAnalyzationEndDate:
      convertEndOfDay(firstAnalyzationDateRange.endDate)?.getTime(),
      edfFileName: edfFileName,
      equipmentId: equipmentId,
      lowerAge: lowerAge,
      upperAge: upperAge,
      prefecture: prefecture,
      gender: gender,
      medicineInfo: medicineInfo,
      sickHistory: sickHistory,
      patientNumber: patientNumber,
      diseaseId: diseaseId,
      waitingForAnalyze: waitingForAnalyze
        ? AnalyzationStatus.WAITING_FOR_ANALYZE
        : "",
      waitingForReCalculating: waitingForReCalculating
        ? AnalyzationStatus.WAITING_FOR_RE_CALCULATING
        : "",
      starting: starting ? AnalyzationStatus.STARTING : "",
      analyzing: analyzing ? AnalyzationStatus.ANALYZING : "",
      calculating: calculating ? AnalyzationStatus.CALCULATING : "",
      confirmed: confirmed ? AnalyzationStatus.CONFIRMED : "",
      waitingForConfirmReport: waitingForConfirmReport
        ? AnalyzationStatus.WAITING_FOR_CONFIRM_REPORT
        : "",
      waitingForConfirm: waitingForConfirm
        ? AnalyzationStatus.WAITING_FOR_CONFIRM
        : "",
      requestForReMeasure: requestForReMeasure
        ? AnalyzationStatus.REQUEST_FOR_RE_MEASURE
        : "",
      failed: failed ? AnalyzationStatus.FAILED : "",
    };
    setFilterLoading(true);
    filterFlag ? await fetchMoreData(filterParams) : await fetchMoreData();
    setFilterLoading(false);
  },[filterFlag, affiliationName, dateRange, firstAnalyzationDateRange, edfFileName, equipmentId, lowerAge, upperAge, prefecture, paginationModel.page,  paginationModel.pageSize])
  
  console.log(analyzationState)
  return (
    <div>
      <FlexWrap>
        {group !== 'coMedical' &&
        <Button
          children={"解析依頼"}
          width={180}
          variant="contained"
          color="primary"
          onClick={() => navigate("/analyzation-request")}
        />}
        <ButtonWrap>
          <Button
            children={"PDFダウンロード"}
            width={180}
            color="secondary"
            disabled={selectList.length === 0}
            onClick={() => {
              if (selectList.length > 10) {
                setSnackState({
                  open: true,
                  message: `対象の選択肢を10個以下に設定してください`,
                  success: false,
                });
                setTimeout(() => {
                  setSnackState({
                    open: false,
                    message: `対象の選択肢を10個以下に設定してください`,
                    success: false,
                  });
                }, 3000);
                return;
              }
              const selectedAnalyzationList: Array<any> = [];
              for (let i = 0; i < selectAnalyzationList.length; i++) {
                if (
                  selectAnalyzationList[i]?.status ===
                    AnalyzationStatus.CONFIRMED ||
                  selectAnalyzationList[i]?.status ===
                    AnalyzationStatus.WAITING_FOR_CONFIRM ||
                  selectAnalyzationList[i]?.status ===
                    AnalyzationStatus.WAITING_FOR_CONFIRM_REPORT
                ) {
                  selectedAnalyzationList.push(selectAnalyzationList[i]);
                } else {
                  setSnackState({
                    open: true,
                    message: `スタータスが確認済み、レポート作成済み、確認待ち（レポート作成済み）の解析のみ選択してください`,
                    success: false,
                  });
                  setTimeout(() => {
                    setSnackState({
                      open: false,
                      message: `スタータスが確認済み、レポート作成済み、確認待ち（レポート作成済み）の解析のみ選択してください`,
                      success: false,
                    });
                  }, 3000);
                  return;
                }
              }
              setOpenPdfDownloadModal(true);
            }}
          />
        </ButtonWrap>
        {(clinic?.isAllowedFFTDownload && group !== 'coMedical') && (
          <ButtonWrap>
            <Button
              children={"FFTダウンロード"}
              width={210}
              color="secondary"
              disabled={selectList.length === 0}
              onClick={() => downloadFTTFiles()}
            />
          </ButtonWrap>
        )}
        {(clinic?.isAllowedTotalDonwload  && group !== 'coMedical') && (
          <ButtonWrap>
            <Button
              children={"CSVダウンロード"}
              width={180}
              color="secondary"
              disabled={selectList.length === 0}
              onClick={() => {
                handleDownLoadCSV();
              }}
            />
          </ButtonWrap>
        )}
      </FlexWrap>
      <Container>
        <AnaluzationForwardTextField
          sx={{ width: 500 }}
          placeholder={"所属で検索"}
          fullWidth
          value={affiliationName}
          onChange={(e: any) => {
            //handleSearchFilter(e.target.value);
            //field.onChange(e);
            setAffiliationName(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div style={{ marginLeft: 20 }}>
          <SearchDetailButton
            onClick={() => setIsOpenSearchBox(!isOpenSearchBox)}
          >
            {"詳細検索"}
          </SearchDetailButton>
        </div>
        <Box marginLeft={"30px"} />
        <Button
          children={"絞り込む"}
          width={200}
          variant="contained"
          color="primary"
          onClick={async () => {
            if (
              affiliationName === "" &&
              dateRange.startDate === null &&
              dateRange.endDate === null &&
              firstAnalyzationDateRange.startDate === null &&
              firstAnalyzationDateRange.endDate === null &&
              edfFileName === "" &&
              equipmentId === "" &&
              lowerAge === "" &&
              upperAge === "" &&
              prefecture === "" &&
              gender === "" &&
              medicineInfo === "" &&
              sickHistory === "" &&
              patientNumber === "" &&
              diseaseId === "" &&
              waitingForAnalyze === false &&
              waitingForReCalculating === false &&
              starting === false &&
              analyzing === false &&
              calculating === false &&
              confirmed === false &&
              waitingForConfirmReport === false &&
              waitingForConfirm === false &&
              requestForReMeasure === false &&
              failed === false
            ) {
              setFilterAnalyzations([]);
              setFilterFlag(false);
              return;
            }
            const filterParams = {
              affiliationName: affiliationName,
              dateRangeStartDate: convertStartOfDay(dateRange.startDate)?.getTime().toString(),
              dateRangeEndDate:  convertEndOfDay(dateRange.endDate)?.getTime().toString(),
              firstAnalyzationStartDate:
                convertStartOfDay(firstAnalyzationDateRange.startDate)?.getTime(),
              firstAnalyzationEndDate:
              convertEndOfDay(firstAnalyzationDateRange.endDate)?.getTime(),
              edfFileName: edfFileName,
              equipmentId: equipmentId,
              lowerAge: lowerAge,
              upperAge: upperAge,
              prefecture: prefecture,
              gender: gender,
              medicineInfo: medicineInfo,
              sickHistory: sickHistory,
              patientNumber: patientNumber,
              diseaseId: diseaseId,
              waitingForAnalyze: waitingForAnalyze
                ? AnalyzationStatus.WAITING_FOR_ANALYZE
                : "",
              waitingForReCalculating: waitingForReCalculating
                ? AnalyzationStatus.WAITING_FOR_RE_CALCULATING
                : "",
              starting: starting ? AnalyzationStatus.STARTING : "",
              analyzing: analyzing ? AnalyzationStatus.ANALYZING : "",
              calculating: calculating ? AnalyzationStatus.CALCULATING : "",
              confirmed: confirmed ? AnalyzationStatus.CONFIRMED : "",
              waitingForConfirmReport: waitingForConfirmReport
                ? AnalyzationStatus.WAITING_FOR_CONFIRM_REPORT
                : "",
              waitingForConfirm: waitingForConfirm
                ? AnalyzationStatus.WAITING_FOR_CONFIRM
                : "",
              requestForReMeasure: requestForReMeasure
                ? AnalyzationStatus.REQUEST_FOR_RE_MEASURE
                : "",
              failed: failed ? AnalyzationStatus.FAILED : "",
            };
            setFilterLoading(true);
            await fetchMoreData(
              filterParams
            ).catch((err) => {
              setFilterLoading(false);
              return;
            });
            setFilterLoading(false);
            setFilterFlag(true)
          }}
        />
        <MuiButton
          variant="text"
          color="primary"
          onClick={() => {
            setAffiliationName("");
            setDateRange({ startDate: null, endDate: null });
            setFirstAnalyzationDateRange({ startDate: null, endDate: null });
            setEdfFileName("");
            setEquipmentId("");
            setLowerAge("");
            setUpperAge("");
            setPrefecture("");
            setGender("");
            setSickHistory("");
            setMedicineInfo("");
            setPatientNumber("");
            setdiseaseId("");
            setWaitingForAnalyze(false);
            setWaitingForReCalculating(false);
            setStarting(false);
            setAnalyzing(false);
            setCalculating(false);
            setConfirmed(false);
            setWaitingForConfirmReport(false);
            setWaitingForConfirm(false);
            setRequestForReMeasure(false);
            setFailed(false);
            setAllFinished(false);
            setAllAnalyzing(false);
            setFilterFlag(false);
            setFilterAnalyzations([]);
          }}
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            color: "#529BC5",
            borderRadius: 46,
            width: 220,
          }}
        >
          {"条件をクリア"}
        </MuiButton>
      </Container>
      <Collapse in={isOpenSearchBox}>
        <SearchArea
          dateRange={dateRange}
          firstAnalyzationDateRange={firstAnalyzationDateRange}
          setDateRange={setDateRange}
          setFirstAnalyzationDateRange={setFirstAnalyzationDateRange}
          edfFileName={edfFileName}
          setEdfFileName={setEdfFileName}
          equipmentId={equipmentId}
          setEquipmentId={setEquipmentId}
          lowerAge={lowerAge}
          setLowerAge={setLowerAge}
          upperAge={upperAge}
          setUpperAge={setUpperAge}
          prefecture={prefecture}
          setPrefecture={setPrefecture}
          gender={gender}
          setGender={setGender}
          sickHistory={sickHistory}
          setSickHistory={setSickHistory}
          medicineInfo={medicineInfo}
          setMedicineInfo={setMedicineInfo}
          patientNumber={patientNumber}
          setPatientNumber={setPatientNumber}
          diseaseId={diseaseId}
          setdiseaseId={setdiseaseId}
          waitingForAnalyze={waitingForAnalyze}
          setWaitingForAnalyze={setWaitingForAnalyze}
          waitingForReCalculating={waitingForReCalculating}
          setWaitingForReCalculating={setWaitingForReCalculating}
          starting={starting}
          setStarting={setStarting}
          analyzing={analyzing}
          setAnalyzing={setAnalyzing}
          calculating={calculating}
          setCalculating={setCalculating}
          confirmed={confirmed}
          setConfirmed={setConfirmed}
          waitingForConfirmReport={waitingForConfirmReport}
          setWaitingForConfirmReport={setWaitingForConfirmReport}
          waitingForConfirm={waitingForConfirm}
          setWaitingForConfirm={setWaitingForConfirm}
          requestForReMeasure={requestForReMeasure}
          setRequestForReMeasure={setRequestForReMeasure}
          failed={failed}
          setFailed={setFailed}
          allFinished={allFinished}
          setAllFinished={setAllFinished}
          allAnalyzing={allAnalyzing}
          setAllAnalyzing={setAllAnalyzing}
        />
      </Collapse>
      <MuiDataGrid
        count={filterFlag ? filterAnalyzations.length ?? [] : total || []}
        start={(paginationModel.page * paginationModel.pageSize)/100}
        analyzations={
          filterFlag ? filterAnalyzations ?? [] : analyzationsData || []}
        handleDownloadEDF={handleDownloadEDF}
        handleDownloadSingleFFT={handleDownloadSingleFFT}
        patients={patientsResult?.result.Items ?? []}
        setOpen={setOpen}
        affiliations={affiliation?.result.affiliations.Items ?? []}
        setAnalyzationState={setAnalyzationState}
        setSelectList={setSelectList}
        setOpenReportModal={setOpenReportModal}
        updateAnalyzation={updateAnalyzation}
        clinic={clinic}
        setIsOpenRejectModal={setIsOpenRejectModal}
        selectList={selectList}
        setOpenDocA4ReportModal={setOpenDocA4ReportModal}
        setOpenDocA3ReportModal={setOpenDocA3ReportModal}
        loading={(filterLoading ||  (loading && analyzationsData.length === 0)) ? true : false}
        sortModel={sortModel}
        setSortModel={setSortModel}
        setPaginationModel={setPaginationModel}
        paginationModel={paginationModel}
        filterFlag={filterFlag}
        group={group}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={"解析情報"}
        maxWidth={"lg"}
        width={900}
      >
        <div style={{ marginTop: 8 }}>
          <HeadlessTable 
            rows={rows.length > 0 ? rows : []} 
            width={280}
            />
        </div>
        <Box marginTop={"32px"}>
          <BodyText style={{ fontSize: 16, marginBottom: 0 }}>
            SleepWellへのコメント
          </BodyText>
          <ForwardTextField
            sx={{ width: "100%", mt: 1 }}
            placeholder={"会員様へのコメントを記載してください"}
            multiline
            value={analyzationState?.commentCustomerUser ?? ""}
            onChange={(e) => {
              if (analyzationState) {
                setAnalyzationState({
                  ...analyzationState,
                  commentCustomerUser: e.target.value as string,
                });
              }
            }}
          />
        </Box>
        <div style={{ marginBottom: 32, marginTop: 32 }}>
          <Button
            onClick={() => updateCommentForClinic()}
            children={"コメントを登録"}
            width={220}
            disabled={disabled}
            isLoading={disabled}
          />
        </div>
        <Accordion
          title="属性情報"
          handleChange={handleChange}
          children={
            <HeadlessTable
              rows={attributeRows.length > 0 ? attributeRows : []}
              width={280}
            />
          }
          expanded={expanded}
          panel={"panel1"}
        />
         <Accordion
          title="解析結果"
          handleChange={handleChange}
          children={
            <HeadlessTable
              rows={analyzationResultRow.length > 0 ? analyzationResultRow : []}
              width={280}
            />
          }
          expanded={expanded}
          panel={"panel2"}
        />
      </Modal>
      <CustomizedSnackbar
        snackState={snackState}
        setSnackState={setSnackState}
      />
      <SimpleBackdrop
        open={isDisabled}
        message={"ファイルをダウンロードしています..."}
      />
      <EditModal
        open={openReportModal}
        onClose={() => {
          setOpenReportModal(false);
        }}
        title={lang === "ja" ? "睡眠レポート" : "Sleep Examination Report"}
        maxWidth={"lg"}
        width={"calc(100% - 96px)"}
        isShowPrintButton={true}
        fileName={`${analyzationState?.edfFileName.replace(".EDF", "")}_report`}
        component={
          clinic?.isAllowedBrowseAllLangReport && (
            <SelectInput
              width={"150px"}
              items={[
                { label: "日本語", value: "ja" },
                { label: "English", value: "en" },
              ]}
              value={lang}
              onChange={(e: any) => setLang(e.target.value)}
            />
          )
        }
      >
        <SleepAnalyticsDetail
          analyzationId={analyzationState?.id ?? ""}
          openReportModal={openReportModal}
          age={Number(analyzationAge) ?? 20}
          measureStartDate={analyzationState?.measureStartDate ?? 0}
          clinic={clinic}
          lang={lang}
          type={analyzationState?.type}
          isImmediateAnalyize={analyzationState?.isImmediateAnalyize}
          edfFileName={analyzationState?.edfFileName}
          patients={patientsResult?.result.Items ?? []}
          patientId={analyzationState?.patientId ?? ""}
        />
      </EditModal>
      {openPdfDownloadModal && (
        <PdfDownloadModal
          open={openPdfDownloadModal}
          onClose={() => {
            setOpenPdfDownloadModal(false);
            setFftDownload(false);
          }}
          fftDownload={fftDownload}
          title={"睡眠レポート"}
          maxWidth={"lg"}
          width={"calc(100% - 96px)"}
          isShowPrintButton={true}
          fileName={`睡眠レポート`}
          selectAnalyzationList={selectAnalyzationList}
        >
          {selectAnalyzationList.map((item) => {
            const patients = patientsResult?.result.Items ?? [];
            const patient = patients.find((p) => p.id === item?.patientId);
            const birthDate = patient?.birthday;
            const measureStartDate = item?.measureStartDate;
            const birthDateYear = dayjs(birthDate).year();
            const measureStartDateYear = dayjs(measureStartDate).year();
            const age = measureStartDateYear - birthDateYear;
            return (
              <SleepAnalyticsDetail
                analyzationId={item?.id ?? ""}
                openReportModal={true}
                age={Number(age) ?? 0}
                measureStartDate={item?.measureStartDate ?? 0}
                isPdfDownload={true}
                clinic={clinic}
                lang={lang}
                type={item?.type}
                edfFileName={item?.edfFileName}
                patients={patientsResult?.result.Items ?? []}
                patientId={item?.patientId ?? ""}
                anaylzation={analyzationState}
              />
            );
          })}
        </PdfDownloadModal>
      )}
      <EditModal
        open={openDocA4ReportModal}
        onClose={() => {
          setOpenDocA4ReportModal(false);
        }}
        title={lang === "ja" ? "睡眠検査結果" : "Result of Sleep Inspection"}
        maxWidth={"lg"}
        width={"calc(100% - 96px)"}
        isShowPrintButton={true}
        fileName={`${analyzationState?.edfFileName.replace(
          ".EDF",
          ""
        )}_dock_a4`}
        component={
          clinic?.isAllowedBrowseAllLangReport && (
            <SelectInput
              width={"150px"}
              items={[
                { label: "日本語", value: "ja" },
                { label: "English", value: "en" },
              ]}
              value={lang}
              onChange={(e: any) => setLang(e.target.value)}
            />
          )
        }
      >
        <SleepAnalyticsDocAReport
          analyzationId={analyzationState?.id ?? ""}
          openReportModal={openDocA4ReportModal}
          age={Number(analyzationAge) ?? 20}
          measureStartDate={analyzationState?.measureStartDate ?? 0}
          patientId={analyzationState?.patientId ?? ""}
          patients={patientsResult?.result.Items ?? []}
          clinic={clinicData ?? null}
          averageItems={averageItems?.data.result.Items ?? []}
          lang={lang}
          isImmediateAnalyize={analyzationState?.isImmediateAnalyize ?? 0}
          snoreQuestion={analyzationState?.snoreQuestion}
          type={analyzationState?.type}
          analyzation={analyzationState}
          affiliation={affiliation?.result.affiliations.Items?.find((doc) => doc.id === analyzationState?.affiliationId) ?? null}
        />
      </EditModal>
      <EditWideModal
        open={openDocA3ReportModal}
        onClose={() => {
          setOpenDocA3ReportModal(false);
        }}
        title={lang === "ja" ? "睡眠検査結果" : "Result of Sleep Inspection"}
        maxWidth={"xl"}
        width={"calc(100% - 96px)"}
        isShowPrintButton={true}
        fileName={`${analyzationState?.edfFileName.replace(".EDF", "")}_dock`}
        component={
          clinic?.isAllowedBrowseAllLangReport && (
            <SelectInput
              width={"150px"}
              items={[
                { label: "日本語", value: "ja" },
                { label: "English", value: "en" },
              ]}
              value={lang}
              onChange={(e: any) => setLang(e.target.value)}
            />
          )
        }
      >
        <SleepAnalyticsDocA3Report
          analyzationId={analyzationState?.id ?? ""}
          openReportModal={openDocA3ReportModal}
          age={Number(analyzationAge) ?? 20}
          measureStartDate={analyzationState?.measureStartDate ?? 0}
          patientId={analyzationState?.patientId ?? ""}
          patients={patientsResult?.result.Items ?? []}
          clinic={clinicData ?? null}
          averageItems={averageItems?.data.result.Items ?? []}
          lang={lang}
          isImmediateAnalyize={analyzationState?.isImmediateAnalyize ?? 0}
          snoreQuestion={analyzationState?.snoreQuestion}
          type={analyzationState?.type}
          analyzation={analyzationState}
        />
      </EditWideModal>
      <EditModal
        open={isOpenRejectModal}
        width={"calc(100% - 96px)"}
        onClose={() => {
          setIsOpenRejectModal(false);
          setSelectList([]);
        }}
        title={"解析メッセージ"}
        maxWidth={"md"}
      >
        <div style={{ marginTop: 16 }}>
          <HeadlessTable rows={rejectMessageList} width={150} />
        </div>
      </EditModal>
    </div>
  );
};

export const getSleepCycle = (
  key: keyof AnalyzationResult,
  analyzationResultData: AnalyzationResult
) => {
  switch (key) {
    case "firstSleepCycle":
      return `${roundToSecondDecimalPlace(
        analyzationResultData["firstSleepCycle"]
      )}(${
        roundToSecondDecimalPlace(
          analyzationResultData["firstSleepDeviation"]
        ) ?? "-"
      })`;
    case "secondSleepCycle":
      return `${roundToSecondDecimalPlace(
        analyzationResultData["secondSleepCycle"]
      )}(${
        roundToSecondDecimalPlace(
          analyzationResultData["secondSleepDeviation"]
        ) ?? "-"
      })`;
    case "thirdSleepCycle":
      return `${roundToSecondDecimalPlace(
        analyzationResultData["thirdSleepCycle"]
      )}(${
        roundToSecondDecimalPlace(
          analyzationResultData["thirdSleepDeviation"]
        ) ?? "-"
      })`;
    case "fourthSleepCycle":
      return `${roundToSecondDecimalPlace(
        analyzationResultData["fourthSleepCycle"]
      )}(${
        roundToSecondDecimalPlace(
          analyzationResultData["fourthSleepDeviation"]
        ) ?? "-"
      })`;
    case "fifthSleepCycle":
      return `${roundToSecondDecimalPlace(
        analyzationResultData["fifthSleepCycle"]
      )}(${
        roundToSecondDecimalPlace(
          analyzationResultData["fifthSleepDeviation"]
        ) ?? "-"
      })`;
    case "sixthSleepCycle":
      return `${roundToSecondDecimalPlace(
        analyzationResultData["sixthSleepCycle"]
      )}(${
        roundToSecondDecimalPlace(
          analyzationResultData["sixthSleepDeviation"]
        ) ?? "-"
      })`;
    default:
      return analyzationResultData[key] ?? "-";
  }
};
const convertEndOfDay = (date: Date | null) => {
  if (!date) return date;
  return new Date(date.setHours(23, 59, 59, 999));
}
const convertStartOfDay = (date: Date | null) => {
  if (!date) return date;
  return new Date(date.setHours(0, 0, 0, 0));
}

const FlexWrap = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  flexWrap: "wrap",
  marginBottom: 20,
});
const ButtonWrap = styled("div")({
  marginLeft: 8,
});

const Container = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "16px",
}));

const FlexLinkWrap = styled("div")`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
